import './styles.scss';
import React, {memo} from 'react';
import { Link } from 'gatsby';

const QuizFooter = () => {

    return (
        <div className="quizFooter">
            <p className="quizFooterText">© AEER PLATFORM INC 8 THE GREEN STE A DOVER, DE 19901</p>
            <div className="quizFooterLinks">
                <Link to="/terms">Terms of user</Link>
                <Link to="/privacy">Privacy policy</Link>
                <Link to="/cookie">Cookie policy</Link>
                <Link to="/money-back">Money-Back Policy</Link>
                <Link to="/cancel">Сancel subscription</Link>
            </div>
        </div>
    )
}

export default memo(QuizFooter);
