import './styles.scss';
import React, {memo, useState} from 'react';

import {Link} from "gatsby";

import {
    LoadingGif
} from "../../images";

type Props = {
    id: number,
    question: string,
    answers: Array<string>,
    arrLength: number,
    lastLink: string
}

const QuizQuestion = ({id, question, answers, arrLength, lastLink}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    let nextStep = +id + 1;

    const linkElem = (text, index) => {
        if(arrLength !== id){
            return <Link to={`/quiz/${nextStep}`} key={index} className="quizAnswer"><span>{text}</span></Link>
        } else{
            return <Link to={`${lastLink}`} key={index} className="quizAnswer"><span>{text}</span></Link>
        }
    }

    if(isLoading){
        return (
            <div className="loadWrap loadWrapQuiz">
                <div className="loadWrapLogo">
                    <div className="loadWrapText">
                        <img width={16} src={LoadingGif} alt=""/>
                        Loading..
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="quizQuestion">
                <h2 className="quizTitle">{question}</h2>
                <div className="quizAnswers">
                    {answers.map((key, index) => (
                        linkElem(key, index)
                    ))}
                </div>
            </div>
        </>     
    )
}

export default memo(QuizQuestion);
